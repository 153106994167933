* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  color: #5d6166ea;
  font-size: .95rem;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-size: cover;
}

.bgText {
  position: absolute;
  font-size: 5rem;
  left: 60px;
  width: 50%;
  z-index: -999;
}

.general {
  line-height: 1.2;
  width: 300px;
  margin: 0 auto;
  padding: 40px;
  background-color: white;
  box-shadow: 1px 3px 25px -8px rgba(0,0,0,0.66);
  -webkit-box-shadow: 1px 3px 25px -8px rgba(0,0,0,0.66);
  -moz-box-shadow: 1px 3px 25px -8px rgba(0,0,0,0.66);
  z-index: 99999 !important;
}

h2 {
  text-align: center;
  margin-bottom: -10px;
}

h3 {
  color: #2599eb;
  text-align: center;
  font-size: 1rem;
}


input {
  background-color: #01569310;
  box-sizing: border-box;
  width: 100%;
  font-size: .9rem;
  padding: 12px 5px 12px;
  margin: 5px auto !important;
  border: none;
  border-bottom: 2px solid rgba(128, 128, 128, 0.795);
}

input:focus {
  outline: none;
  border-bottom: 2px solid rgba(128, 128, 128, 0.795);
}

input[type="submit"] {
  background-color: #015693;
  border: none;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 10px 0px;
  margin-top: 15px !important;
}

input[type="submit"]:hover {
  background-color: #0072C6;
}

.error {
  color: #D12933;
  margin-top: 0;
  text-align: center;
}

.loader {
  background-color: rgba(0, 0, 0, 0.281);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.icon {
  color: #0072C6;
}


